export function shopDate(tableDate, shopObj, CarList) {
    shopObj.forEach((item, index) => {
        tableDate.forEach((el, i) => {
            if (shopObj[index].id == tableDate[i].id) {
                tableDate[i].check = shopObj[index].check
                CarList.push(tableDate[i])
                // console.log('i:', tableDate[i]);
            }
        })
    });
}