import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from '@/store'
import {Message} from 'element-ui'

Vue.use(VueRouter)

const routes = [
    // 首页
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home'),
    },
    // 媒体价格
    {
        path: '/media',
        name: 'mediaView',
        component: () => import('@/views/Media'),
        children: [
            // 新闻媒体
            {
                path: 'News',
                name: 'MediaNews',
                component: () => import('@/views/Media/News')
            },
            // 自媒体
            {
                path: 'WeMedia',
                name: 'MediaWeMedia',
                component: () => import('@/views/Media/WeMedia')
            },
            // 纸媒体
            {
                path: 'PaperMedia',
                name: 'MediaPaperMedia',
                component: () => import('@/views/Media/PaperMedia')
            },
            // 公众号
            {
                path: 'OfficialAccounts',
                name: 'MediaOfficialAccounts',
                component: () => import('@/views/Media/OfficialAccounts')
            },
            // 微博
            {
                path: 'wb',
                name: 'wb',
                component: () => import('@/views/Media/wb')
            },
            // 小红书
            {
                path: 'RedBook',
                name: 'MediaRedBook',
                component: () => import('@/views/Media/RedBook')
            },
            // 短视频
            {
                path: 'ShortVideo',
                name: 'MediaShortVideo',
                component: () => import('@/views/Media/ShortVideo')
            },
            // 媒体套餐
            {
                path: 'SetMedia',
                name: 'SetMedia',
                component: () => import('@/views/Media/SetMedia')
            },
        ]
    },
    // 广告
    {
        path: '/placard_media_index',
        name: 'placard_media_index',
        component: () => import('@/views/placard_media/placard_media_index'),
        children: [
            {
                path: 'outdoors',
                name: 'outdoors',
                component: () => import('@/views/placard_media/outdoors')
            },
            {
                path: 'network',
                name: 'network',
                component: () => import('@/views/placard_media/network')
            },
            {
                path: 'journal',
                name: 'journal',
                component: () => import('@/views/placard_media/journal')
            },
            {
                path: 'tv',
                name: 'tv',
                component: () => import('@/views/placard_media/tv')
            },
        ],
    },
    // 关于我们
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About')
    },
    // 注册
    {
        path: '/register',
        name: 'register',
        meta: '注册',
        component: () => import('@/views/Register')
    },
    // 结算
    {
        path: '/close',
        name: 'close',
        component: () => import('@/views/Close')
    },
    // 一言一文
    {
        path: '/word',
        name: 'word',
        component: () => import('@/views/Word')
    },
    // 享视界
    {
        path: '/enjoy',
        name: 'enjoy',
        component: () => import('@/views/enjoy')
    },
    // 产品合作
    {
        path: '/product',
        name: 'product',
        component: () => import('@/views/Product')
    },
    // 可信网站
    {
        path: '/credible',
        name: 'credible',
        component: () => import('@/views/credible')
    },
    // 可信网站
    {
        path: '/technical',
        name: 'technical',
        component: () => import('@/views/technical')
    },
    // 技巧指南
    {
        path: '/guide',
        name: 'guide',
        component: () => import('@/views/Guide')
    },
    // 文章详情
    {
        path: '/GuideDetail',
        name: 'GuideDetail',
        component: () => import('@/views/GuideDetail')
    },
    // 一言一文操作界面
    {
        path: '/wordNav',
        name: 'wordNav',
        component: () => import('@/views/Word/index')
    },
    // 企业简介
    {
        path: '/EnterpriseProfile',
        name: 'EnterpriseProfile',
        meta: '企业简介',
        component: () => import('@/views/Word/EnterpriseProfile')
    },
    // 个人简介
    {
        path: '/IndividualResume',
        name: 'IndividualResume',
        meta: '个人简介',
        component: () => import('@/views/Word/IndividualResume')
    },
    // 产品简介
    {
        path: '/productResume',
        name: 'productResume',
        meta: '产品简介',
        component: () => import('@/views/Word/ProductResume')
    },
    // 议论文
    {
        path: '/esay',
        name: 'esay',
        meta: '议论文',
        component: () => import('@/views/Word/Esay')
    },
    // 记叙文
    {
        path: '/narration',
        name: 'narration',
        meta: '记叙文',
        component: () => import('@/views/Word/Narration')
    },
    // 广告语
    {
        path: '/slogan',
        name: 'slogan',
        meta: '广告语',
        component: () => import('@/views/Word/Slogan')
    },
    // 祝福语
    {
        path: '/greeting',
        name: 'greeting',
        meta: '祝福语',
        component: () => import('@/views/Word/Greeting')
    },
    // 错别字修改
    {
        path: '/wrongly',
        name: 'wrongly',
        meta: '错别字修改',
        component: () => import('@/views/Word/Wrongly')
    },
    // 标题提炼
    {
        path: '/titleExtraction',
        name: 'titleExtraction',
        meta: '标题提炼',
        component: () => import('@/views/Word/TitleExtraction')
    },
    // 文稿润色
    {
        path: '/manuscriptPolishing',
        name: 'manuscriptPolishing',
        meta: '文稿润色',
        component: () => import('@/views/Word/ManuscriptPolishing')
    },
    // 文稿改写
    {
        path: '/manuscriptRewriting',
        name: 'manuscriptRewriting',
        meta: '文稿改写',
        component: () => import('@/views/Word/ManuscriptRewriting')
    },
    // 短视频脚本
    {
        path: '/shortVideo',
        name: 'shortVideo',
        meta: '短视频脚本',
        component: () => import('@/views/Word/ShortVideo')
    },
    // 纪录片脚本
    {
        path: '/documentary',
        name: 'documentary',
        meta: '纪录片脚本',
        component: () => import('@/views/Word/Documentary')
    },
    // 营销软文
    {
        path: '/article',
        name: 'article',
        meta: '营销软文',
        component: () => import('@/views/Word/article')
    },
    // 说明书
    {
        path: '/instructions',
        name: 'instructions',
        meta: '说明书',
        component: () => import('@/views/Word/instructions')
    },
    // 商业计划书
    {
        path: '/business',
        name: 'business',
        meta: '说明书',
        component: () => import('@/views/Word/business')
    },
    // ppt
    {
        path: '/ppt',
        name: 'ppt',
        meta: 'PPT内容框架',
        component: () => import('@/views/Word/ppt')
    },
    // AI小说/歌词
    {
        path: '/novel',
        name: 'novel',
        meta: 'AI小说/歌词',
        component: () => import('@/views/Word/novel')
    },
    // AI产品/活动策划方案
    {
        path: '/activities',
        name: 'activities',
        meta: 'AI策划方案',
        component: () => import('@/views/Word/activities')
    },
    // AI发言稿
    {
        path: '/speechdraft',
        name: 'speechdraft',
        meta: 'AI发言稿',
        component: () => import('@/views/Word/speechdraft')
    },
    // AI演讲稿
    {
        path: '/speechtext',
        name: 'speechtext',
        meta: 'AI演讲稿',
        component: () => import('@/views/Word/speechtext')
    },
    // 小红书文案
    {
        path: '/word_redbook',
        name: 'word_redbook',
        meta: '小红书文案',
        component: () => import('@/views/Word/word_redbook')
    },
    // AI声明
    {
        path: '/ai_statement',
        name: 'ai_statement',
        meta: 'AI声明',
        component: () => import('@/views/Word/ai_statement')
    },
    // AI商评
    {
        path: '/business_review',
        name: 'business_review',
        meta: 'AI商评',
        component: () => import('@/views/Word/business_review')
    },
    // AI访谈
    {
        path: '/interview',
        name: 'interview',
        meta: 'AI访谈',
        component: () => import('@/views/Word/interview')
    },
    // AI分析报告
    {
        path: '/analysis_report',
        name: 'analysis_report',
        meta: 'AI分析报告',
        component: () => import('@/views/Word/analysis_report')
    },

    // AI操作界面
    {
        path: '/aiNav',
        name: 'aiNav',
        component: () => import('@/views/ai/aiNav')
    },
    // AI绘画
    {
        path: '/paint',
        name: 'paint',
        meta: 'AI绘画',
        component: () => import('@/views/ai/paint')
    },
    // 一点成诗
    {
        path: '/verse',
        name: 'verse',
        meta: '一点成诗',
        component: () => import('@/views/Word/verse')
    },
    // AI黑白上色
    {
        path: '/blackWhite',
        name: 'blackWhite',
        meta: '黑白图像上色',
        component: () => import('@/views/ai/blackWhite')
    },
    // 图像风格转换
    {
        path: '/transImg',
        name: 'transImg',
        meta: '图片风格转换',
        component: () => import('@/views/ai/transImg')
    },
    // ai背景生成
    {
        path: '/image_matting',
        name: 'image_matting',
        meta: '图片背景生成',
        component: () => import('@/views/ai/image_matting')
    },
    // 一键抠图
    {
        path: '/change_back',
        name: 'change_back',
        meta: '一键抠图',
        component: () => import('@/views/ai/change_back')
    },
    // 人物头像动漫化
    //  {
    //   path: '/cartoon',
    //   name: 'cartoon',
    //   meta: '人物头像动漫化',
    // component: () => import('@/views/ai/cartoon')
    // },
    // 律师函
    {
        path: '/lawyer',
        name: 'lawyer',
        meta: '律师函',
        component: () => import('@/views/ai/lawyer')
    },
    // 版权侵权告知书
    {
        path: '/tort',
        name: 'tort',
        meta: '版权侵权告知书',
        component: () => import('@/views/ai/tort')
    },
    // 起诉书
    {
        path: '/prosecute',
        name: 'prosecute',
        meta: '起诉书',
        component: () => import('@/views/ai/prosecute')
    },
    // AI智能海报
    {
        path: '/placard',
        name: 'placard',
        meta: 'AI智能海报',
        component: () => import('@/views/ai/placard')
    },
    // AI数字人_3D
    {
        path: '/digitalHuman_3d',
        name: 'digitalHuman_3d',
        meta: '智能3D数字人',
        component: () => import('@/views/digitalHumans/digitalHuman_3d')
    },
    // AI数字人_2D
    {
        path: '/digitalHuman_2d',
        name: 'digitalHuman_2d',
        meta: '智能2D数字人',
        component: () => import('@/views/digitalHumans/digitalHuman_2d')
    },
    // AI数字人_2D_精品
    {
        path: '/digitalHuman_2d_boutique',
        name: 'digitalHuman_2d_boutique',
        meta: '智能2D精品数字人',
        component: () => import('@/views/digitalHumans/digitalHuman_2d_boutique')
    },
    // AI数字人_定制照片
    {
        path: '/digitalHuman_customizePhotos',
        name: 'digitalHuman_customizePhotos',
        meta: '定制照片',
        component: () => import('@/views/digitalHumans/digitalHuman_customizePhotos')
    },
    // AI数字人_定制声音
    {
        path: '/digitalHuman_customizeSound',
        name: 'digitalHuman_customizeSound',
        meta: '定制声音克隆',
        component: () => import('@/views/digitalHumans/digitalHuman_customizeSound')
    },
    // AI数字人_形象定制
    {
        path: '/digitalHuman_imageCustomization',
        name: 'digitalHuman_imageCustomization',
        meta: '2D数字人形象定制',
        component: () => import('@/views/digitalHumans/digitalHuman_imageCustomization')
    },

    // // AI对话
    // {
    //   path: '/aiMessage',
    //   name: 'aiMessage',
    //   meta: 'AI对话',
    //   component: () => import('@/views/ai/aiMessage')
    // },
    // // AI视频
    // {
    //   path: '/aiVideo',
    //   name: 'aiVideo',
    //   meta: 'AI视频',
    //   component: () => import('@/views/ai/aiVideo')
    // },
    // 个人
    {
        path: '/placard_user',
        name: '/placard_user',
        component: () => import('@/views/placard_user/placard_user'),
        meta: {title: '会员中心',},
        children: [
            // 广告系统-个人中心
            {
                path: 'placard_index',
                name: 'placard_index',
                component: () => import('@/views/placard_user/placard_index'),
                meta: '广告系统-首页',
            },
            {
                path: 'placard_fill',
                name: 'placard_fill',
                component: () => import('@/views/placard_user/placard_fill'),
                meta: '广告填写',
            },
            // 户外广告
            {
                path: 'user_outdoors_placard_media',
                name: 'user_outdoors_placard_media',
                component: () => import('@/views/placard_user/placard_user_media/user_outdoors_placard_media'),
                meta: '广告资源-户外广告'
            },
            // 网络广告
            {
                path: 'user_network_placard_media',
                name: 'user_network_placard_media',
                component: () => import('@/views/placard_user/placard_user_media/user_network_placard_media'),
                meta: '广告资源-网络广告'
            },
            // 刊物广告
            {
                path: 'user_journal_placard_media',
                name: 'user_journal_placard_media',
                component: () => import('@/views/placard_user/placard_user_media/user_journal_placard_media'),
                meta: '广告资源-刊物广告'
            },
            // 电视广告
            {
                path: 'user_tv_placard_media',
                name: 'user_tv_placard_media',
                component: () => import('@/views/placard_user/placard_user_media/user_tv_placard_media'),
                meta: '广告资源-电视广告'
            },
            // 广告系统-申请发票
            {
                path: 'placard_apply_for_invoice',
                name: 'placard_apply_for_invoice',
                component: () => import('@/views/placard_user/placard_apply_for_invoice'),
                meta: '财务管理-申请发票',
                children: [
                    {
                        path: 'placard_apply_for_invoice_data',
                        name: 'placard_apply_for_invoice_data',
                        component: () => import('@/views/placard_user/placard_apply_for_invoice_data'),
                    },
                    {
                        path: 'placard_apply_for_invoice_list',
                        name: 'placard_apply_for_invoice_list',
                        component: () => import('@/views/placard_user/placard_apply_for_invoice_list'),
                    },
                ]
            },
            {
                path: 'placard_manuscript_preview',
                name: 'placard_manuscript_preview',
                component: () => import('@/views/placard_user/placard_manuscript_preview'),
                meta: '稿件预览',
            },
            // 广告订单
            {
                path: 'placard_order',
                name: 'placard_order',
                component: () => import('@/views/placard_user/placard_order'),
                meta: '订单管理-广告订单',
            },
            // 广告系统-我的工单
            {
                path: 'my_place_order',
                name: 'my_place_order',
                component: () => import('@/views/user/my_place_order'),
                meta: '订单管理-我的工单',
            },
            {
                path: 'pub_placard',
                name: 'pub_placard',
                component: () => import('@/views/user/pub_placard'),
                meta: '我的媒介-发布媒介',
                beforeEnter: (to, from, next) => {
                    let user_info = JSON.parse(localStorage.getItem('user_info'));
                    console.log(user_info);
                    if (user_info.is_guanggao == 1) {
                        next()
                    } else {
                        next('/placard_user/placard_index');
                        Message.error('您当前暂无权限')
                    }
                },
                children: [
                    {
                        path: 'pub_outdoors',
                        component: () => import('@/views/user/pub_placard/pub_outdoors'),

                    },
                    {
                        path: 'pub_network',
                        component: () => import('@/views/user/pub_placard/pub_network'),
                    },
                    {
                        path: 'pub_journal',
                        component: () => import('@/views/user/pub_placard/pub_journal'),
                    },
                    {
                        path: 'pub_tv',
                        component: () => import('@/views/user/pub_placard/pub_tv'),
                    },
                ]
            },
            // 广告-添加资源
            {
                path: 'add_placard',
                name: 'add_placard',
                component: () => import('@/views/placard_user/add_placard/add_placard'),
                meta: '我的媒介-发布媒介-添加媒介-户外',
                children: [
                    // 户外
                    {
                        path: 'add_outdoors',
                        name: 'add_outdoors',
                        component: () => import('@/views/placard_user/add_placard/add_outdoors'),
                    },
                    // 网络
                    {
                        path: 'add_network',
                        name: 'add_network',
                        component: () => import('@/views/placard_user/add_placard/add_network'),
                    },
                    // 网络
                    {
                        path: 'add_journal',
                        name: 'add_journal',
                        component: () => import('@/views/placard_user/add_placard/add_journal'),
                    },
                    // 网络
                    {
                        path: 'add_tv',
                        name: 'add_tv',
                        component: () => import('@/views/placard_user/add_placard/add_tv'),
                    },
                ],
            },
            // 广告-充值
            {
                path: 'placard_refill',
                name: 'placard_refill',
                component: () => import('@/views/placard_user/placard_refill'),
                meta: '财务管理-在线充值',
            },
            // 广告-提现管理
            {
                path: 'placard_withdraw',
                name: 'placard_withdraw',
                component: () => import('@/views/placard_user/placard_withdraw'),
                meta: '财务管理-提现管理',
            },
            // 广告-帐单细明
            {
                path: 'placard_account_details',
                name: 'placard_account_details',
                component: () => import('@/views/placard_user/placard_account_details'),
                meta: '财务管理-帐单细明',
            },
            // 广告-发票列表
            {
                path: 'placard_invoice',
                name: 'placard_invoice',
                component: () => import('@/views/placard_user/placard_invoice'),
                meta: '财务管理-发票列表',
            },
            // 广告-钱包转换
            {
                path: 'placard_wallet_conversion',
                name: 'placard_wallet_conversion',
                component: () => import('@/views/placard_user/placard_wallet_conversion'),
                meta: '财务管理-发票列表-钱包转换',
            },
            // 广告订单列表
            {
                path: 'placard_manuscript',
                name: 'placard_manuscript',
                component: () => import('@/views/placard_user/placard_manuscript'),
                meta: '我的媒介-广告订单列表',
                beforeEnter: (to, from, next) => {
                    let user_info = JSON.parse(localStorage.getItem('user_info'));
                    if (user_info.is_guanggao == 1) {
                        next()
                    } else {
                        next('/placard_user/placard_index');
                        Message.error('您当前暂无权限')
                    }
                }
            },
            // 广告订单列表
            {
                path: 'placard_after_sal',
                name: 'placard_after_sal',
                component: () => import('@/views/placard_user/placard_after_sal'),
                meta: '我的媒介-售后记录-广告订单',
                beforeEnter: (to, from, next) => {
                    let user_info = JSON.parse(localStorage.getItem('user_info'));
                    if (user_info.is_guanggao == 1) {
                        next()
                    } else {
                        next('/placard_user/placard_index');
                        Message.error('您当前暂无权限')
                    }
                }
            },
            // 广告_查看稿件
            {
                path: 'placard_lookdetails',
                name: 'placard_lookdetails',
                meta: '查看稿件',
                component: () => import('@/views/placard_user/placard_lookdetails')
            },
            // 消息中心
            {
                path: 'MessageCenter',
                name: 'MessageCenter',
                component: () => import('@/views/user/MessageCenter'),
                meta: '财务管理-账户管理-消息中心',
            },
            // 资料密码
            {
                path: 'Resource',
                name: 'Resource',
                component: () => import('@/views/user/Resource'),
                meta: '财务管理-账户管理-资料密码',
            },
            // 企业认证
            {
                path: 'Enterprise',
                name: 'Enterprise',
                component: () => import('@/views/user/Enterprise'),
                meta: '财务管理-账户管理-企业认证',
            },
            // 平台帮助
            {
                path: 'Help',
                name: 'Help',
                component: () => import('@/views/user/Help'),
                meta: '财务管理-账户管理-平台帮助',
            },
        ]
    },
    {
        path: '/user',
        name: '/user',
        component: () => import('@/views/user/user.vue'),
        meta: {title: '会员中心',},
        children: [
            // 个人中心
            {
                path: 'index',
                name: 'index',
                component: () => import('@/views/user/index'),
                meta: '媒介系统-首页',
            },
            // 优惠套餐
            {
                path: 'SetMenu',
                name: 'SetMenu',
                component: () => import('@/views/user/SetMenu'),
                meta: '媒介选择',
                children: [
                    {
                        path: 'SetMenuList',
                        name: 'SetMenuList',
                        component: () => import('@/views/user/SetMenuList'),
                        meta: '优惠套餐',
                    },
                    {
                        path: 'fill',
                        name: 'fill',
                        component: () => import('@/views/user/Fill'),
                        meta: '稿件填写',
                    },

                    {
                        path: 'ManuscriptPreview',
                        name: 'ManuscriptPreview',
                        component: () => import('@/views/user/ManuscriptPreview'),
                        meta: '稿件预览',
                    },
                    {
                        path: 'ManuscriptDetails',
                        name: 'ManuscriptDetails',
                        component: () => import('@/views/user/ManuscriptDetails'),
                        meta: '订单详情',
                    },
                ]
            },
            // 媒介发布
            {
                path: 'user_media',
                name: 'user_media',
                component: () => import('@/views/user/Media'),
                meta: '媒介选择-媒介发布',
                children: [
                    // 新闻媒体
                    {
                        path: 'News',
                        name: 'user_mediaNews',
                        component: () => import('@/views/user_media/News'),
                    },
                    // 自媒体
                    {
                        path: 'userWeMedia',
                        name: 'userWeMedia',
                        component: () => import('@/views/user_media/WeMedia'),

                    },
                    // 纸媒体
                    {
                        path: 'userPaperMedia',
                        name: 'userPaperMedia',
                        component: () => import('@/views/user_media/PaperMedia'),
                    },
                    // 公众号
                    {
                        path: 'userOfficialAccounts',
                        name: 'userOfficialAccounts',
                        component: () => import('@/views/user_media/OfficialAccounts'),
                    },
                    // 微博
                    {
                        path: 'userWb',
                        name: 'userWb',
                        component: () => import('@/views/user_media/Wb'),
                    },
                    // 小红书
                    {
                        path: 'userRedBook',
                        name: 'userRedBook',
                        component: () => import('@/views/user_media/RedBook'),
                    },
                    // 短视频
                    {
                        path: 'userShortVideo',
                        name: 'userShortVideo',
                        component: () => import('@/views/user_media/ShortVideo'),
                    },

                ]

            },
            // 草稿箱
            {
                path: 'draft',
                name: 'draft',
                component: () => import('@/views/user/Draft'),
                meta: '媒介选择-草稿箱',
            },
            // 媒介订单
            {
                path: 'MediaOrder',
                name: 'MediaOrder',
                component: () => import('@/views/user/MediaOrder'),
                meta: '订单管理-媒介订单',
            },

            // 媒介订单-修改页
            {
                path: 'edit_fill',
                name: 'edit_fill',
                component: () => import('@/views/user/edit_fill'),
                meta: '订单管理-媒介订单',
            },
            // 套餐订单
            {
                path: 'PackageOrder',
                name: 'PackageOrder',
                component: () => import('@/views/user/PackageOrder'),
                meta: '订单管理-套餐订单',
            },
            // 我的工单
            {
                path: 'MyOrder',
                name: 'MyOrder',
                component: () => import('@/views/user/MyOrder'),
                meta: '订单管理-我的工单',
            },
            // 一言一文
            {
                path: 'Myword',
                name: 'Myword',
                component: () => import('@/views/user/Word'),
                meta: '一言一文',
            },
            // 数字人使用记录
            {
                path: 'digitalHumanRecord',
                name: 'digitalHumanRecord',
                component: () => import('@/views/user/digitalHumanRecord'),
                meta: '数字人记录',
            },
            //用户权益包
            {
                path: 'userBenefitPackage',
                name: 'userBenefitPackage',
                component: () => import('@/views/user/userBenefitPackage/index'),
                meta: '用户权益包',
                // beforeEnter: (to, from, next) => {
                //     let user_info = JSON.parse(localStorage.getItem('user_info'));
                //     if (user_info.is_right_package == 0) {
                //         next()
                //     } else {
                //         next('/user/index');
                //         Message.error('您当前暂无权限')
                //     }
                // },
            },
            // 发布媒介
            {
                path: 'pub_media',
                name: 'pub_media',
                component: () => import('@/views/user/pub_media'),
                meta: '我的媒介-发布媒介',
                children: [
                    {
                        path: 'pubNews',
                        component: () => import('@/views/user/pub_media/pubNews'),
                    },
                    {
                        path: 'pubWeMedia',
                        component: () => import('@/views/user/pub_media/pubWeMedia'),
                    },
                    {
                        path: 'pubPaperMedia',
                        component: () => import('@/views/user/pub_media/pubPaperMedia'),
                    },
                    {
                        path: 'pubOfficialAccountsMedia',
                        component: () => import('@/views/user/pub_media/pubOfficialAccounts'),
                    },
                    {
                        path: 'pubWbMedia',
                        component: () => import('@/views/user/pub_media/pubWb'),
                    },
                    {
                        path: 'pubRedbookMedia',
                        component: () => import('@/views/user/pub_media/pubRedbook'),
                    },
                    {
                        path: 'pubShortVideoMedia',
                        component: () => import('@/views/user/pub_media/pubShortVideo'),
                    },
                ],

            },


            // 添加资源
            {
                path: 'add_resource',
                name: 'add_resource',
                component: () => import('@/views/user/add_resource'),
                meta: '我的媒介-发布媒介-添加媒介',
                children: [
                    // 新闻媒体
                    {
                        path: 'News',
                        name: 'News',
                        component: () => import('@/views/user/News'),
                    },
                    // 自媒体
                    {
                        path: 'WeMedia',
                        name: 'WeMedia',
                        component: () => import('@/views/user/WeMedia'),
                    },
                    // 纸媒体
                    {
                        path: 'PaperMedia',
                        name: 'PaperMedia',
                        component: () => import('@/views/user/PaperMedia'),
                    },
                    // 公众号
                    {
                        path: 'OfficialAccounts',
                        name: 'OfficialAccounts',
                        component: () => import('@/views/user/OfficialAccounts'),
                    },
                    // 微博
                    {
                        path: 'Wb',
                        name: 'Wb',
                        component: () => import('@/views/user/Wb'),
                    },
                    // 小红书
                    {
                        path: 'RedBook',
                        name: 'RedBook',
                        component: () => import('@/views/user/RedBook'),
                    },
                    // 短视频
                    {
                        path: 'ShortVideo',
                        name: 'ShortVideo',
                        component: () => import('@/views/user/ShortVideo'),
                    },

                ]
            },

            // 稿件管理
            {
                path: 'after_sale',
                name: 'after_sale',
                component: () => import('@/views/user/after_sale'),
                meta: '我的媒介-售后记录',
            },
            // 稿件管理
            {
                path: 'Manuscript',
                name: 'Manuscript',
                component: () => import('@/views/user/Manuscript'),
                meta: '我的媒介-稿件管理',
            },

            // 在线充值
            {
                path: 'refill',
                name: 'refill',
                component: () => import('@/views/user/Refill'),
                meta: '财务管理-在线充值',
            },
            // 提现管理
            {
                path: 'Withdraw',
                name: 'Withdraw',
                component: () => import('@/views/user/Withdraw'),
                meta: '财务管理-提现管理',
            },

            // 充值记录-消费记录-退款记录
            {
                path: 'AccountDetails',
                name: 'AccountDetails',
                component: () => import('@/views/user/AccountDetails'),
                meta: '财务管理-账单细明',
            },
            // 发票列表
            {
                path: 'Invoice',
                name: 'Invoice',
                component: () => import('@/views/user/Invoice'),
                meta: '财务管理-发票列表',
            },
            // 申请发票
            {
                path: 'apply_for_invoice',
                name: 'apply_for_invoice',
                component: () => import('@/views/user/apply_for_invoice'),
                meta: '财务管理-申请发票',
                children: [

                    {
                        path: 'apply_for_invoice_data',
                        name: 'apply_for_invoice_data',
                        component: () => import('@/views/user/apply_for_invoice_data'),
                    },
                    {
                        path: 'apply_for_invoice_list',
                        name: 'apply_for_invoice_list',
                        component: () => import('@/views/user/apply_for_invoice_list'),
                    },
                ]
            },

            // 添加开票资料
            {
                path: 'AddInvoice',
                name: 'AddInvoice',
                component: () => import('@/views/user/AddInvoice'),
                meta: '财务管理-发票列表-添加开票资料',
            },
            // 钱包转换
            {
                path: 'wallet_conversion',
                name: 'wallet_conversion',
                component: () => import('@/views/user/wallet_conversion'),
                meta: '财务管理-发票列表-钱包转换',
            },

            // 消息中心
            {
                path: 'MessageCenter',
                name: 'MessageCenter',
                component: () => import('@/views/user/MessageCenter'),
                meta: '财务管理-账户管理-消息中心',
            },
            // 资料密码
            {
                path: 'Resource',
                name: 'Resource',
                component: () => import('@/views/user/Resource'),
                meta: '财务管理-账户管理-资料密码',
            },
            // 企业认证
            {
                path: 'Enterprise',
                name: 'Enterprise',
                component: () => import('@/views/user/Enterprise'),
                meta: '财务管理-账户管理-企业认证',
            },
            // 平台帮助
            {
                path: 'Help',
                name: 'Help',
                component: () => import('@/views/user/Help'),
                meta: '财务管理-账户管理-平台帮助',
            },
            // 再次发稿
            {
                path: 'or_fill',
                name: 'or_fill',
                component: () => import('@/views/user/or_fill'),
                meta: '稿件填写',
            },
            // 文章详情
            {
                path: 'TextDetails',
                name: 'TextDetails',
                meta: '文章详情',
                component: () => import('@/views/user/TextDetails')
            },
            // 查看稿件
            {
                path: 'lookdetails',
                name: 'lookdetails',
                meta: '查看稿件',
                component: () => import('@/views/user/LookDetails')
            },

        ],
    },
    // 广告系统
    //数字人
    {
        path: '/digitalHumans',
        name: 'digitalHumans',
        meta: 'AI数字人',
        component: () => import('@/views/digitalHumans/index.vue')
    }

]


const router = new VueRouter({
    mode: 'history',
    // scrollBehavior: () => ({ y: 0 }),
    routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
// router.beforeEach((to, from, next) => {

// })
export default router
