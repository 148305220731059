import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
import media_screen_data from './media_screen_data'
import placard from './placard'
import mediaOrder from './mediaOrder'
import manuscript from './manuscript'
import packOrder from './packOrder'
import my_order from './my_order'
import {media_list, placard_list} from '@/util/nav_list'
import * as state from "@/util/nav_list";

export default new Vuex.Store({
    plugins: [
        // 把vuex的数据存储到sessionStorage
        createPersistedState({
            storage: window.localStorage,
        }),
    ],//会自动保存创建的状态。刷新还在
    modules: {
        media_screen_data: media_screen_data,
        placard: placard,
        mediaOrder: mediaOrder,
        manuscript: manuscript,
        packOrder: packOrder,
        my_order: my_order,
    },
    getters: {
        stateDate(state) {
            // console.log(state);
            return
        },

    },
    state: {
        nav_show: 0,
        // 媒介-广告身份
        standing: 1,
        userInfo: {},
        // 登录弹窗
        loginShow: false,
        // 忘记密码弹窗
        passwordShow: false,
        MediaNav: [],
        Media: [],
        // 媒体资源
        TypeItem: {}, //媒体资源选项卡
        shopObj: [],
        menu_active: '/user/index',
        nav_list: media_list,
        placard_list: placard_list,
        activeTitle: '首页',
        // 添加媒体-请求分类
        active: 1,
        AddRs: 0,//添加媒体导航id
        // AddRsId:undefined, //添加媒体-媒体id
        /*----------------------------------------------- */
        /*媒介/套餐下单 */
        FillForm: {
            cover_image: '', //封面
            title: '',
            content: '', //编译器内容
            limit_time: '', //时间选择器
            file: '',
            url: '',
            video_url: '',
            original_url: '', //原文链接
            remark: '',
            medium_ids: '',
            package_ids: '',
        },
        //发票
        apply_for_invoice_title: '1',
        // 广告-发票
        placard_apply_for_invoice_title: '1',
        /*-----------------发布稿件_是否已经选择媒体-------------*/
        fillActive: true,
        /* 我的媒介导航栏选中*/
        pubNav: 0,
    },
    mutations: {
        nav_tab(state, index) {
            // console.log('state',index)
            state.nav_show = index

        },
        login_tab(state, status) {
            state.loginShow = status
        },
        shopPush(state, n) {
            this.state.shopObj = []
            n.forEach((element, index) => {
                return this.state.shopObj.push({
                    category_id: element.category_id ? element.category_id : 0,
                    id: element.id,
                    title: element.title,
                    medium_price: element.price,
                    medium_ids: element.category_id ? element.id : 0,
                    package_ids: element.category_id ? element.id : 0,
                    check: element.check
                })
            });
        },
        // 权益包添加入购物车
        shopPush2(state, n) {
            this.state.shopObj = []
            n.forEach((element, index) => {
                return this.state.shopObj.push({
                    category_id: element.category_id ? element.category_id : 0,
                    id: element.id,
                    right_package_id: element.rp_id,
                    title: element.title,
                    medium_price: element.price,
                    medium_ids: element.category_id ? element.id : 0,
                    package_ids: element.category_id ? element.id : 0,
                    check: element.check
                })
            });
            // console.log('购物车',this.state.shopObj)
        },
        // 个人中心导航切换
        childTab(state, title) {

        },
        SET_DATA(state, data) {
            state[data] = data.value;
        },
        SET_ORDER_DATE(state, data) {
            state[data.form][data.index] = data.value;
        },
        SETACTIVEMENU(state, menu) {
            // type 区分类型 1:菜单 2:子菜单
            state.menu_active = menu;
            console.log(menu);
        },
        SHOP_RESET(state, data) {
            state.shopObj = data;
        },
        // NAV_LIST_SPLICE(state, data) {
        //     state.nav_list[data.index].children.splice(data.i)
        //     console.log(state.nav_list[data.index].children[data.i]);
        // },
        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo;
        },
        //删除媒介中心的'用户权益包'菜单
        NAV_LIST_SPLICE(state, {parentIndex, childIndex}) {
            if (state.nav_list[parentIndex] && state.nav_list[parentIndex].children) {
                state.nav_list[parentIndex].children.splice(childIndex, 1);
            }
        },
        //删除媒介中心的'用户权益包'菜单
        NAV_LIST_DEL(state) {
            state.nav_list = [
                {
                    id: 0,
                    title: '首页',
                    imgUrl: require('@/assets/user/home.png'),
                    path: '/user/index'
                },
                {
                    id: 1,
                    title: '产品中心',
                    imgUrl: require('@/assets/user/media.png'),
                    selectStatus: false,
                    children: [
                        {
                            id: 1,
                            title: '媒介套餐',
                            path: '/user/SetMenu/SetMenuList'
                        },
                        {
                            id: 2,
                            title: '媒介资源',
                            path: '/user/user_media/News',
                        },
                        {
                            id: 3,
                            title: '一言一文',
                            path: '/wordNav',
                        },
                        {
                            id: 4,
                            title: '享视界',
                            path: '/aiNav'
                        }
                    ],
                },
                {
                    id: 2,
                    title: '稿件发布',
                    imgUrl: require('@/assets/user/manuscript.png'),
                    selectStatus: false,

                    children: [
                        {
                            id: 1,
                            title: '资讯门户发布',
                            path: '/user/SetMenu/fill?id=1'
                        },
                        {
                            id: 2,
                            title: '自媒体发布',
                            path: '/user/SetMenu/fill?id=2'
                        },
                        {
                            id: 3,
                            title: '纸媒介发布',
                            path: '/user/SetMenu/fill?id=3'
                        },
                        {
                            id: 4,
                            title: '公众号发布',
                            path: '/user/SetMenu/fill?id=4'
                        },

                        {
                            id: 5,
                            title: '微博发布',
                            path: '/user/SetMenu/fill?id=5'
                        },
                        {
                            id: 6,
                            title: '小红书发布',
                            path: '/user/SetMenu/fill?id=6'
                        },
                        {
                            id: 7,
                            title: '短视频发布',
                            path: '/user/SetMenu/fill?id=7'
                        },
                        {
                            id: 8,
                            title: '套餐发布',
                            path: '/user/SetMenu/fill?id=8'
                        },
                        {
                            id: 9,
                            title: '草稿箱',
                            path: '/user/Draft'
                        },
                    ],
                },
                {
                    id: 3,
                    title: '订单管理',
                    imgUrl: require('@/assets/user/order.png'),
                    selectStatus: false,

                    children: [
                        {
                            id: 1,
                            order_type: 'order',
                            order_status_total_num: 0,
                            title: '媒介订单',
                            path: '/user/MediaOrder'
                        },
                        {
                            id: 2,
                            order_type: 'package',
                            package_status_total_num: 0,
                            title: '套餐订单',
                            path: '/user/PackageOrder'
                        },
                        {
                            id: 3,
                            title: 'AI记录',
                            path: '/user/Myword'
                        },
                        {
                            id: 4,
                            title: '数字人记录',
                            path: '/user/digitalHumanRecord'
                        },
                        {
                            id: 5,
                            title: '我的工单',
                            path: '/user/MyOrder'
                        },
                    ],
                },
                {
                    id: 4,
                    title: '我的媒介',
                    imgUrl: require('@/assets/user/MyMedia.png'),
                    selectStatus: false,
                    children: [
                        {
                            id: 1,
                            title: '我的资源',
                            path: '/user/pub_media/pubNews'
                        },
                        {
                            id: 2,
                            order_type: 'draft',
                            draft_status_total_num: 0,
                            title: '我的收稿',
                            path: '/user/Manuscript'
                        },
                        {
                            id: 3,
                            title: '售后记录',
                            path: '/user/after_sale'
                        },
                    ],
                },
                {
                    id: 5,
                    title: '财务管理',
                    imgUrl: require('@/assets/user/money.png'),
                    selectStatus: false,

                    children: [
                        {
                            id: 1,
                            title: '在线充值',
                            path: '/user/refill'
                        },
                        {
                            id: 2,
                            title: '提现管理',
                            path: '/user/Withdraw'
                        },
                        {
                            id: 3,
                            title: '账单细明',
                            path: '/user/AccountDetails'
                        },
                        {
                            id: 4,
                            title: '发票列表',
                            path: '/user/Invoice'
                        },
                        {
                            id: 5,
                            title: '申请发票',
                            path: '/user/apply_for_invoice/apply_for_invoice_data'
                        },
                        {
                            id: 6,
                            title: '钱包转换',
                            path: '/user/wallet_conversion'
                        },
                    ],
                },
                {
                    id: 6,
                    title: '账户管理',
                    imgUrl: require('@/assets/user/user.png'),
                    selectStatus: false,
                    children: [
                        {
                            id: 1,
                            title: '消息中心',
                            path: '/user/MessageCenter'
                        },
                        {
                            id: 2,
                            title: '资料密码',
                            path: '/user/Resource'
                        },
                        {
                            id: 3,
                            title: '企业认证',
                            path: '/user/Enterprise'
                        },
                        {
                            id: 4,
                            title: '平台帮助',
                            path: '/user/Help'
                        },
                    ],
                },
            ]
        },
        //重置媒介中心菜单
        NAV_LIST_RESET(state) {
            state.nav_list = media_list
        },

        //非广告主 -删除广告中心的'我的广告'菜单
        PLACARD_LIST_DEL(state) {
            state.placard_list = state.placard_list.filter(item => {
                return item.id !== 4
            })
        },
        //重置广告中心菜单
        PLACARD_LIST_RESET(state) {
            state.placard_list = placard_list
        },

    },
    actions: {
        //更新用户信息
        updateUserInfo({commit}) {
            const userInfo = JSON.parse(localStorage.getItem('user_info'));
            commit('SET_USER_INFO', userInfo);
        },
        //删除媒介中心的'用户权益包'菜单
        removeSpecificChild({commit, state}) {
            if (state.userInfo.is_right_package === 0) {
                commit('NAV_LIST_RESET');
            } else {
                commit('NAV_LIST_DEL');
            }
        },
        //判断是否为广告主
        is_Advertiser({commit, state}) {
            if (state.userInfo.is_guanggao === 0) {
                commit('PLACARD_LIST_DEL');
            } else {
                commit('PLACARD_LIST_RESET');
            }
        },
    }
})
