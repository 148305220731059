import axios from "axios";
import util from "../util/util.js";
import $store from '@/store'
import $router from '@/router'

const service = axios.create({
    baseURL: '/', //请求的公共地址部分
    timeout: 60 * 60 * 3 * 1000, //设置超时时间
})
service.interceptors.request.use(
    // config的请求信息
    // return config //将配置完成的config对象返回出去 如果不返回 请求则不会进行
    config => {
        let user_info = util.getLocalStorage('user_info')
        // console.log("user_info == ", user_info);
        // console.log(config.url.indexOf(blackApi));

        // if (config.url.indexOf(blackApi) === -1 && !user_info) {
        //     if(!flig) {
        //       flig = true
        //       window.electronAPI.clearTimer()
        //       window.electronAPI.errorInfo()
        //       MessageBox.confirm('您已经登出，请重新登录', '提示', {
        //         confirmButtonText: '去登录',
        //         type: 'warning',
        //         callback: (action) => {
        //           flig = false
        //           router.push('/')
        //         }
        //       })
        //       return Promise.reject(new Error('您已经登出'))
        //     }
        // }

        if (user_info) {
            config.headers['token'] = user_info.token
        }
        config.headers['Content-Type'] = 'application/json'

        return config
    }, err => {
        // 请求发生错误时的处理 抛出错误
        Promise.reject(err)
    }
)
// response
service.interceptors.response.use(res => {
    return Promise.resolve(res)
}, err => {
    // 服务器响应发生错误时处理
    if (err.response.status == 401) {
        return $router.push('/')
    }
    Promise.reject(err)
})
export default service